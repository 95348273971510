import { useNavigate, useSearchParams } from "@remix-run/react";
import {
  Children,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";
import { Modal, ModalContent } from "./Modal";

const EXIT_DURATION = 100;

type ModalRouterChildren = ReactElement<ModalRouteProps>;
interface ModalRouterProps {
  children: ModalRouterChildren | ModalRouterChildren[];
}

export const ModalRouter = ({ children }: ModalRouterProps) => {
  const [searchParams] = useSearchParams();

  const action = searchParams.get("action");

  const childProps =
    Children.map(children, (child) => {
      if (!isValidElement(child)) return;
      return child.props;
    }) ?? [];

  const props = childProps.find(({ path }) => path === action);

  const [show, setShow] = useState(false);

  const nodeRef = useRef(null);

  const navigate = useNavigate();

  const exit = () => {
    setShow(false);
    setTimeout(() => navigate(-1), EXIT_DURATION);
  };

  useEffect(() => {
    if (props) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [props]);

  return (
    <CSSTransition
      classNames="modal"
      in={show}
      nodeRef={nodeRef}
      timeout={{
        enter: 200,
        exit: EXIT_DURATION,
      }}
      unmountOnExit
    >
      {props ? (
        <Modal exit={exit}>
          {/* eslint-disable-next-line react/prop-types */}
          <ModalContent exit={exit}>{props.component}</ModalContent>
        </Modal>
      ) : (
        <></>
      )}
    </CSSTransition>
  );
};

interface ModalRouteProps {
  path: string;
  component: ReactNode;
}

export const ModalRoute = ({ component }: ModalRouteProps) => component;
