import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoBoomtap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    fill="none"
    viewBox="230 230 340 340"
    {...props}
  >
    <mask
      id="mask0_9:1218"
      width={100}
      height={100}
      x={230}
      y={230}
      maskUnits="userSpaceOnUse"
    >
      <path fill="#C4C4C4" d="M230 230h100v100H230z" />
    </mask>
    <g mask="url(#mask0_9:1218)">
      <path fill="url(#paint0_linear_9:1218)" d="M230 230h100v340H230z" />
    </g>
    <mask
      id="mask1_9:1218"
      width={100}
      height={100}
      x={350}
      y={230}
      maskUnits="userSpaceOnUse"
    >
      <path fill="#C4C4C4" d="M350 230h100v100H350z" />
    </mask>
    <g mask="url(#mask1_9:1218)">
      <path fill="url(#paint1_linear_9:1218)" d="M350 230h100v340H350z" />
    </g>
    <mask
      id="mask2_9:1218"
      width={100}
      height={100}
      x={470}
      y={230}
      maskUnits="userSpaceOnUse"
    >
      <path fill="#C4C4C4" d="M470 230h100v100H470z" />
    </mask>
    <g mask="url(#mask2_9:1218)">
      <path fill="url(#paint2_linear_9:1218)" d="M470 230h100v340H470z" />
    </g>
    <mask
      id="mask3_9:1218"
      width={100}
      height={100}
      x={230}
      y={350}
      maskUnits="userSpaceOnUse"
    >
      <path fill="#C4C4C4" d="M230 350h100v100H230z" />
    </mask>
    <g mask="url(#mask3_9:1218)">
      <path fill="url(#paint3_linear_9:1218)" d="M230 230h100v340H230z" />
    </g>
    <mask
      id="mask4_9:1218"
      width={100}
      height={100}
      x={350}
      y={350}
      maskUnits="userSpaceOnUse"
    >
      <path fill="#C4C4C4" d="M350 350h100v100H350z" />
    </mask>
    <g mask="url(#mask4_9:1218)">
      <path fill="url(#paint4_linear_9:1218)" d="M350 230h100v340H350z" />
    </g>
    <mask
      id="mask5_9:1218"
      width={100}
      height={100}
      x={470}
      y={350}
      maskUnits="userSpaceOnUse"
    >
      <path fill="#C4C4C4" d="M470 350h100v100H470z" />
    </mask>
    <g mask="url(#mask5_9:1218)">
      <path fill="url(#paint5_linear_9:1218)" d="M470 230h100v340H470z" />
    </g>
    <mask
      id="mask6_9:1218"
      width={100}
      height={100}
      x={230}
      y={470}
      maskUnits="userSpaceOnUse"
    >
      <path fill="#C4C4C4" d="M230 470h100v100H230z" />
    </mask>
    <g mask="url(#mask6_9:1218)">
      <path fill="url(#paint6_linear_9:1218)" d="M230 230h100v340H230z" />
    </g>
    <mask
      id="mask7_9:1218"
      width={100}
      height={100}
      x={350}
      y={470}
      maskUnits="userSpaceOnUse"
    >
      <path fill="#C4C4C4" d="M350 470h100v100H350z" />
    </mask>
    <g mask="url(#mask7_9:1218)">
      <path fill="url(#paint7_linear_9:1218)" d="M350 230h100v340H350z" />
    </g>
    <mask
      id="mask8_9:1218"
      width={100}
      height={100}
      x={470}
      y={470}
      maskUnits="userSpaceOnUse"
    >
      <path fill="#C4C4C4" d="M470 470h100v100H470z" />
    </mask>
    <g mask="url(#mask8_9:1218)">
      <path fill="url(#paint8_linear_9:1218)" d="M470 230h100v340H470z" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_9:1218"
        x1={280}
        x2={280}
        y1={230}
        y2={570}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9752B3" />
        <stop offset={1} stopColor="#F59EAD" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9:1218"
        x1={400}
        x2={400}
        y1={230}
        y2={570}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9752B3" />
        <stop offset={1} stopColor="#F59EAD" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_9:1218"
        x1={520}
        x2={520}
        y1={230}
        y2={570}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9752B3" />
        <stop offset={1} stopColor="#F59EAD" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_9:1218"
        x1={280}
        x2={280}
        y1={230}
        y2={570}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9752B3" />
        <stop offset={1} stopColor="#F59EAD" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_9:1218"
        x1={400}
        x2={400}
        y1={230}
        y2={570}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9752B3" />
        <stop offset={1} stopColor="#F59EAD" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_9:1218"
        x1={520}
        x2={520}
        y1={230}
        y2={570}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9752B3" />
        <stop offset={1} stopColor="#F59EAD" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_9:1218"
        x1={280}
        x2={280}
        y1={230}
        y2={570}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9752B3" />
        <stop offset={1} stopColor="#F59EAD" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_9:1218"
        x1={400}
        x2={400}
        y1={230}
        y2={570}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9752B3" />
        <stop offset={1} stopColor="#F59EAD" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_9:1218"
        x1={520}
        x2={520}
        y1={230}
        y2={570}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9752B3" />
        <stop offset={1} stopColor="#F59EAD" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgLogoBoomtap;
