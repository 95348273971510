import { Link } from "@remix-run/react";
import { LogoDiscord, LogoEmail, LogoMessenger } from "./icons";

export default function Footer() {
  return (
    <footer className="footer footer-center p-8 gap-y-[1rem] text-base-content">
      <div>
        <p>Your feedback is gold. Reach out!</p>
      </div>
      <div>
        <div className="grid grid-flow-col gap-4">
          <Link
            to="https://m.me/316221825225488"
            className="flex items-center"
            target="_blank"
            rel="noreferrer"
          >
            <LogoMessenger height="32px" />
          </Link>
          <Link
            to="https://discord.gg/tDzrgR9m3Z"
            className="flex items-center"
            target="_blank"
            rel="noreferrer"
          >
            <LogoDiscord height="32px" />
          </Link>
          <Link
            to="mailto:boomtap.app@outlook.com"
            className="flex items-center"
            target="_blank"
            rel="noreferrer"
          >
            <LogoEmail height="40px" />
          </Link>
        </div>
      </div>
    </footer>
  );
}
