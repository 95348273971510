import { Link } from "@remix-run/react";
import { useForm } from "react-hook-form";
import { IconDownload } from "./icons";
import { convertToGoogleDriveRoute } from "~/libs/google-drive.client";
import { ErrorMessage } from "./Form";

const EXAMPLE_URL =
  "https://drive.google.com/file/d/10ZKzj_ihTSxDvnk-Yt9QE61vaD-q4d-v";

type Inputs = {
  googleDriveUrl: string;
};

export const FileLoader = () => {
  const {
    formState: { errors, isValid },
    register,
    trigger,
    watch,
  } = useForm<Inputs>({
    reValidateMode: "onBlur",
  });

  const currentUrl = watch("googleDriveUrl");
  const validate = () => trigger("googleDriveUrl");

  return (
    <div className="flex items-center justify-center h-full p-4">
      <form className="form-control w-full max-w-2xl">
        <div className="mb-8 relative">
          <label className="label" htmlFor="url">
            <span className="label-text">Google Drive Link</span>
          </label>
          <input
            aria-invalid={Boolean(errors.googleDriveUrl)}
            aria-required="true"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            className={`input input-bordered input-lg w-full bg-transparent${
              errors.googleDriveUrl && "input-error"
            }`}
            id="url"
            placeholder={`e.g. ${EXAMPLE_URL}`}
            {...register("googleDriveUrl", {
              pattern: {
                value:
                  /^(https:\/\/)?drive\.google\.com\/file\/d\/[a-zA-Z0-9-_]{33}(.*)$/i,
                message:
                  "Sorry, only Google Drive file links are accepted for now.",
              },
              required: "This field is required.",
            })}
            type="text"
          />
          <ErrorMessage name="googleDriveUrl" errors={errors} />
        </div>
        <div className="flex justify-end">
          {isValid ? (
            <Link
              to={convertToGoogleDriveRoute(currentUrl)}
              className="btn btn-primary btn-wide phone:btn-block"
              prefetch="intent"
            >
              <IconDownload />
              Download
            </Link>
          ) : (
            <button
              className="btn btn-primary btn-wide phone:btn-block"
              type="button"
              onClick={validate}
            >
              <IconDownload />
              Download
            </button>
          )}
        </div>
      </form>
    </div>
  );
};
