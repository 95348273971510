import { Links, Meta, Outlet, Scripts } from "@remix-run/react";
import type { LinksFunction } from "@remix-run/node";
import { type ReactNode } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FileLoader } from "./components/FileLoader";
import stylesheet from "~/styles/tailwind.css?url";
import transitionStyles from "~/styles/transition.css?url";
import { ClearPrompt } from "./components/ClearPrompt";
import { ModalRoute, ModalRouter } from "./components/ModalRouter";

const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
  { rel: "stylesheet", href: transitionStyles },
  { rel: "shortcut icon", href: "/favicon.ico" },
];

const queryClient = new QueryClient();

function App() {
  return (
    <Document>
      <QueryClientProvider client={queryClient}>
        <Layout>
          <Outlet />
        </Layout>
      </QueryClientProvider>
    </Document>
  );
}

interface DocumentProps {
  children: ReactNode;
}

function Document({ children }: DocumentProps) {
  return (
    <html lang="en" data-theme="black">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Boomtap</title>
        <meta
          name="description"
          content="A browser-based launchpad to play with audio samples hosted on Google Drive or on your own device."
        />
        <Meta />
        <Links />
      </head>

      <body className="flex flex-col min-h-screen justify-between">
        {children}
        <Scripts />
      </body>
    </html>
  );
}

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => (
  <>
    <Navbar />
    <main className="flex justify-center">{children}</main>
    <Footer />
    <ModalRouter>
      <ModalRoute component={<FileLoader />} path="import" />
      <ModalRoute component={<ClearPrompt />} path="clear" />
    </ModalRouter>
  </>
);

export default App;
export { links };
