import {
  ErrorMessage as HFErrorMessage,
  FieldValuesFromFieldErrors,
} from "@hookform/error-message";
import { ReactNode } from "react";
import { FieldErrors, FieldName } from "react-hook-form";
import { animated, useSpring } from "react-spring";

type ErrorMessageContainerProps = {
  children?: ReactNode;
};

const ErrorMessageContainer = ({ children }: ErrorMessageContainerProps) => {
  const props = useSpring({
    to: {
      marginTop: children ? 0 : -30,
      opacity: children ? 1 : 0,
    },
    from: { marginTop: -30, opacity: 0 },
  });
  return (
    <div className="flex pt-2 absolute text-sm text-error">
      <animated.div className="overflow-hidden" role="alert" style={props}>
        {children}
      </animated.div>
    </div>
  );
};

interface ErrorMessageProps {
  errors?: FieldErrors;
  name: FieldName<FieldValuesFromFieldErrors<FieldErrors>>;
}

export const ErrorMessage = ({ name, errors }: ErrorMessageProps) => (
  <HFErrorMessage errors={errors} name={name} as={<ErrorMessageContainer />} />
);
