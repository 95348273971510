import { Form, useLocation } from "@remix-run/react";
import { useLiveQuery } from "dexie-react-hooks";
import {
  LogoBoomtapWithWordmark,
  IconUpload,
  IconTrash,
  LogoBoomtap,
} from "~/components/icons";
import { idb } from "~/libs/idb";

const Navbar = () => {
  const files = useLiveQuery(() => idb.files.toArray());
  const location = useLocation();

  return (
    <header className="w-full max-w-screen-xl flex items-center mx-auto p-1">
      <nav className="navbar bg-base-100">
        <div className="flex-1">
          <LogoBoomtap className="w-12 h-auto sm:hidden" />
          <LogoBoomtapWithWordmark className="w-36 phone:hidden" />
        </div>
        <div className="flex justify-end flex-1">
          <div className="menu menu-horizontal gap-2 pr-0">
            <Form action={`${location.pathname}`}>
              <button
                aria-controls="modal"
                aria-label="Import a new sound kit"
                className="btn btn-primary phone:btn-square"
                name="action"
                value="import"
              >
                <IconUpload />
                <span className="phone:hidden">Import</span>
              </button>
            </Form>
            {Boolean(files?.length) && (
              <Form action={`${location.pathname}`}>
                <button
                  aria-controls="modal"
                  aria-label="Discard the current sound kit"
                  className="btn btn-square btn-outline btn-primary"
                  name="action"
                  value="clear"
                >
                  <IconTrash width="18px" className="fill-[oklch(var(--p))]" />
                </button>
              </Form>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
