import { type ReactNode } from "react";

interface ModalProps {
  children: ReactNode;
  exit: () => void;
}

export const Modal = ({ children, exit }: ModalProps) => (
  <div className="fixed flex justify-center items-center w-full h-full bg-black/50 backdrop-blur-[2px]">
    <form method="dialog" className="modal-backdrop w-full h-full">
      <button
        aria-controls="modal"
        aria-label="Close active modal"
        onClick={exit}
        formNoValidate
      />
    </form>
    <dialog
      aria-modal={true}
      className="bg-neutral modalContent max-w-7xl w-[calc(100%-2rem)] sm:w-[calc(100%-5rem)] h-5/6 max-h-full"
      open={true}
      tabIndex={-1}
    >
      {children}
    </dialog>
  </div>
);

interface ModalContentProps {
  children: ReactNode;
  title?: string;
  exit: () => void;
}
export const ModalContent = ({ children, exit, title }: ModalContentProps) => (
  <div className="w-full h-full">
    {title && (
      <div className="flex justify-center">
        <p className="text-xs">{title}</p>
      </div>
    )}

    <form method="dialog">
      <button
        aria-controls="modal"
        aria-label="Close active modal"
        className="btn btn-sm btn-ghost absolute right-2 top-2 text-2xl"
        formNoValidate
        onClick={exit}
      >
        ✕
      </button>
    </form>

    {children}
  </div>
);
