import { useNavigate } from "@remix-run/react";
import { idb } from "~/libs/idb";

export const ClearPrompt = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  const clear = async () => {
    idb.metadata.clear();
    await idb.files.clear();
    navigate("/");
  };

  return (
    <div className="flex items-center justify-center h-full">
      <div className="flex flex-col items-center">
        <p className="text-xl md:text-2xl mb-8">Clear all sounds?</p>
        <div className="flex flex-col md:grid md:grid-cols-2 gap-4">
          <button
            aria-label="Clear"
            className="btn btn-primary btn-wide"
            onClick={clear}
          >
            Clear
          </button>
          <button
            className="btn btn-outline btn-primary btn-wide"
            onClick={goBack}
          >
            Keep
          </button>
        </div>
      </div>
    </div>
  );
};
