import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoMessenger = (props: SVGProps<SVGSVGElement>) => (
  <svg width={50} height={50} fill="none" viewBox="0 0 36 36" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M1 17.99C1 8.515 8.423 1.5 18 1.5s17 7.015 17 16.49-7.423 16.49-17 16.49c-1.72 0-3.37-.227-4.921-.654a1.36 1.36 0 0 0-.908.067l-3.374 1.49a1.36 1.36 0 0 1-1.909-1.203l-.092-3.024a1.36 1.36 0 0 0-.457-.97C3.03 27.23 1 22.947 1 17.99m11.785-3.1-4.993 7.922c-.48.76.455 1.617 1.17 1.074l5.365-4.07a1.02 1.02 0 0 1 1.229-.004l3.972 2.979a2.55 2.55 0 0 0 3.687-.68l4.994-7.924c.479-.76-.456-1.617-1.172-1.073l-5.364 4.07a1.02 1.02 0 0 1-1.229.004l-3.971-2.979a2.55 2.55 0 0 0-3.688.68"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLogoMessenger;
