import * as React from "react";
import type { SVGProps } from "react";
const SvgIconUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <path d="M1.333 8.667C1.333 8.333 1.667 8 2 8s.667.333.667.667V12c0 .736.597 1.333 1.333 1.333h8c.736 0 1.333-.597 1.333-1.333V8.667a.667.667 0 1 1 1.334 0V12A2.667 2.667 0 0 1 12 14.667H4A2.667 2.667 0 0 1 1.333 12z" />
    <rect width={1.333} height={9.333} x={7.333} y={1.333} rx={0.667} />
    <path d="M8.024 2.252 5.138 5.138a.668.668 0 0 1-.943-.943L7.53.862a.667.667 0 0 1 .917-.024l3.333 3a.666.666 0 1 1-.892.991z" />
  </svg>
);
export default SvgIconUpload;
