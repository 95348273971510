import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={50}
    height={50}
    viewBox="0 0 256 256"
    {...props}
  >
    <g
      style={{
        stroke: "none",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "none",
        fillRule: "nonzero",
        opacity: 1,
      }}
    >
      <path
        d="M75.546 78.738H14.455C6.484 78.738 0 72.254 0 64.283V25.716c0-7.97 6.485-14.455 14.455-14.455h61.091c7.97 0 14.454 6.485 14.454 14.455v38.567c0 7.971-6.484 14.455-14.454 14.455m-61.091-63.25c-5.64 0-10.228 4.588-10.228 10.228v38.567c0 5.64 4.588 10.229 10.228 10.229h61.091c5.64 0 10.228-4.589 10.228-10.229V25.716c0-5.64-4.588-10.228-10.228-10.228z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          fill: "#fff",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="scale(2.81)"
      />
      <path
        d="M11.044 25.917 43.456 57.5c2.014 1.962 5.105-1.122 3.088-3.088L14.132 22.83c-2.014-1.963-5.105 1.122-3.088 3.087"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          fill: "#fff",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="scale(2.81)"
      />
      <path
        d="m46.544 57.5 32.412-31.582c2.016-1.965-1.073-5.051-3.088-3.088L43.456 54.412c-2.016 1.965 1.073 5.051 3.088 3.088"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          fill: "#fff",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="scale(2.81)"
      />
      <path
        d="M78.837 64.952 57.269 44.499c-2.039-1.933-5.132 1.149-3.088 3.088L75.749 68.04c2.039 1.933 5.132-1.15 3.088-3.088M14.446 68.039l21.568-20.453c2.043-1.938-1.048-5.022-3.088-3.088L11.358 64.951c-2.043 1.938 1.048 5.023 3.088 3.088"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          fill: "#fff",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="scale(2.81)"
      />
    </g>
  </svg>
);
export default SvgLogoEmail;
