import * as React from "react";
import type { SVGProps } from "react";
const SvgIconTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <path d="M11 2v2h10V2zM4 6v2h24V6zm3.992 3.875-1.984.25S7 18.075 7 27v1h18v-1c0-8.926.992-16.875.992-16.875l-1.984-.25S23.12 17.399 23.043 26H8.957c-.077-8.601-.965-16.125-.965-16.125m4.994.96-1.972.33S12 17.11 12 23h2c0-6.111-1.014-12.164-1.014-12.164m6.028 0S18 16.89 18 23h2c0-5.889.986-11.836.986-11.836z" />
  </svg>
);
export default SvgIconTrash;
